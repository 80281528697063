import { Box, OTPInput, Button } from '@axazara/raiton-atoms';
import { useForm } from '@axazara/raiton-molecules';

export function InvitationForm({
  onSubmit,
  acceptInvitationMutation,
}: {
  onSubmit: any;
  acceptInvitationMutation: any;
}) {
  const acceptInvitationForm = useForm({ initialValues: { otp_code: '' } });
  return (
    <Box component="form" onSubmit={acceptInvitationForm.onSubmit(onSubmit)}>
      <Box className="grid justify-center mb-20">
        <OTPInput
          radius={12}
          size="lg"
          required
          onChange={(res) => {
            acceptInvitationForm.setFieldValue('otp_code', res);
          }}
          allowedCharacters="numeric"
        />
      </Box>
      <Box className="flex justify-center">
        <Button fullWidth className="text-p-01-semibold" type="submit" loading={acceptInvitationMutation.isLoading}>
          Configurer mon compte
        </Button>
      </Box>
    </Box>
  );
}

import { Box, Text, Table, Tag, ActionIcon, Switch } from '@axazara/raiton-atoms';
import { SearchStatusOutline, AddOutline } from '@axazara/raiton-icons';
import { showErrorNotification, showSuccessNotification } from '@/utils';
import { Creative, Creatives, formatDate } from 'utilities';
import { useUpdateCreativesPublicStatus } from '../services';
import { Link } from 'react-router-dom';

export default function CreativesTable({ creatives, refetch }: { creatives: Creatives; refetch?: any }) {
  return (
    <Box className="w-full overflow-hidden rounded-xl border border-neutral-80">
      <Table className="rounded-xl overflow-hidden border-collapse  border-spacing-0">
        <Table.Head className="text-p-01-semibold border-b border-neutral-80 text-neutral-20 rounded-xl !bg-neutral">
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">Name</Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">Type</Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">Public</Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">Date</Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold flex justify-end">View</Table.HeadCell>
        </Table.Head>
        <Table.Body>
          {creatives?.map((creative) => <CreativesTableRow creative={creative} key={creative.id} refetch={refetch} />)}
        </Table.Body>
      </Table>
    </Box>
  );
}

function CreativesTableRow({ creative, refetch }: { creative: Creative; refetch: any }) {
  const useChangeCreativePublicStatusMutation = useUpdateCreativesPublicStatus({ id: creative.id as string });

  async function handlePayoutMethodStatusChange(value: boolean) {
    try {
      const response = await useChangeCreativePublicStatusMutation.mutateAsync({ is_public: value });

      if (refetch) refetch();
      showSuccessNotification((response as any).message);
    } catch (error) {
      showErrorNotification((error as any).message);
    }
  }

  return (
    <Table.Row key={creative.id} className="border-b border-neutral-90">
      <Table.Cell>
        <Text className="text-p-01 text-neutral-40">{creative.title}</Text>
      </Table.Cell>
      <Table.Cell>
        {creative.type === 'image' && (
          <Tag className="text-p-01-semibold normal-case bg-success border border-success-60 py-3" size="lg">
            <Text component="span" className="text-success-30 text-p-01-semibold">
              Image
            </Text>
          </Tag>
        )}

        {creative.type === 'video' && (
          <Tag className="text-p-01-semibold normal-case bg-primary border border-primary-60 py-3" size="lg">
            <Text component="span" className="text-primary-30 text-p-01-semibold">
              Video
            </Text>
          </Tag>
        )}
        {creative.type === 'text' && (
          <Tag className="text-p-01-semibold normal-case bg-secondary border border-secondary-60 py-3" size="lg">
            <Text component="span" className="text-secondary-30 text-p-01-semibold">
              Text
            </Text>
          </Tag>
        )}
      </Table.Cell>
      <Table.Cell>
        <Text className="text-p-01-medium text-neutral-40">
          <Switch
            styles={(theme) => ({
              track: {
                minWidth: 52,
                borderRadius: 24,
                backgroundColor: theme.colors.neutral[8],
                border: 0,
              },
              root: {
                width: 52,
              },
              thumb: {
                border: 'none',
                left: 4,
                'input:checked + * > &': {
                  left: 'calc(100% - 32px)',
                },
              },
            })}
            size="xl"
            checked={creative.is_public}
            onChange={({ currentTarget }) => handlePayoutMethodStatusChange(currentTarget.checked)}
            thumbIcon={
              creative.is_public ? (
                <Box>
                  <img src="/images/mark.svg" alt="mark_icons" />
                </Box>
              ) : (
                <Box style={{ transform: 'rotate(45deg)' }}>
                  <AddOutline className=" text-neutral-30 w-6 h-6" />
                </Box>
              )
            }
          />
        </Text>
      </Table.Cell>
      <Table.Cell>
        <Text className="text-p-01 text-neutral-40">
          {formatDate(creative.created_at, 'en', 'D MMM YYYY, HH:mm:ss')}
        </Text>
      </Table.Cell>
      <Table.Cell className="flex justify-end">
        <Box component={Link} to={`/creatives/${creative.id}`} className="flex space-x-2">
          <ActionIcon color="primary" size="xl" radius={100} className="p-0.5">
            <SearchStatusOutline className="w-6 h-6 text-primary-50" />
          </ActionIcon>
        </Box>
      </Table.Cell>
    </Table.Row>
  );
}
